html, body, #root,
.wrapper {
	height:100%;
}

.wrapper{
	padding-top:100px;
	min-height:100%;
	background:lightblue;
	
}
.fa-twitter{
	background:black;
	color:white;
	padding:5px;
	border-radius:5px;
	font-size:24px;
}

.transition{
	
  transition: height 1000ms ease-in-out;
}

h1{
	text-align:center;
}

#quote-box{
	margin-left:auto;
	margin-right:auto;
	min-height:350px;
	text-align:left;
	display: flex;
	flex-direction: column;
	background:lightgray;
	max-width:500px;
	width:75%;
	border-radius:10px;
	white-space: pre-wrap;
	transition:flex 1s ease-out;
}

#text {
	text-align:center;
	background:lightgray;
	margin-left:auto;
	margin-right:auto;
	width:80%;
}

#author{
	text-align:right;
	width:90%;
}

#tweet-quote{
	display:inline-block;
	text-decoration:none;
	color:black;
	background: lightgray;
	margin-left:10px;
	width:20%;
}

#new-quote{
	display:inline-block;
	background:orange;
	font-size:16px;
	font-weight:600;
	width:40%;
	margin: 10px;
	padding:10px 5px;
}

.buttonTweetCont{
	
	display:flex;
	background:lightgray;
	justify-content:space-between;
	align-items:center;
	border-radius:0 0 10px 10px;
	
}


